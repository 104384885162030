<template>
  <main class="row m-0">
    <p>
      Ici on met des petits outils en vrac qui servent en
      interne à l’association Framasoft.<br />
      Des trucs futiles ou qu’on expérimente.
    </p>
    <ul class="tips">
      <li
        v-for="(tool, key) in tools"
        :key="key"
      >
        <div class="tip-content text-center">
          <div class="front">
            <h2 v-html="tool.title"></h2>
            <p>
              <img
                alt=""
                :src="`${$t('baseurl')}img/screens/${key}.png`"
              />
            </p>
          </div>

          <div class="back">
            <p
              class="desc"
              v-html="tool.desc"
            ></p>
            <p class="go">
              <a
                class="btn btn-outline-soutenir"
                :href="tool.url"
              >
                <i class="fas fa-arrow-right"></i>
                S’y rendre
              </a>
            </p>
          </div>
        </div>
      </li>
    </ul>
  </main>
</template>

<script>
export default {
  data() {
    return {
      tools: {
        marklets: {
          title: 'Bookmarklets',
          desc: 'Marque-pages «&nbsp;intelligents&nbsp;» pour disposer de fonctionnalités avancées',
          url: 'https://framalab.org/framarklets',
        },
        gege: {
          title: 'GéGé',
          desc: 'Générateur de BD du Geektionnerd',
          url: 'https://framalab.org/gknd-creator/',
        },
        quickmockup: {
          title: 'QuickMockup',
          desc: 'Outil de création de maquette de logiciel',
          url: 'https://framalab.org/quickMockup/',
        },
        avatars: {
          title: 'Avatars',
          desc: 'Générateur d’avatars de chats',
          url: 'https://avatars.framasoft.org/',
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
/* Tips */
.tips {
  list-style: none;
  padding: 0px;
  margin:0 auto;

  @media (max-width: 740px) { width:340px; }
  @media (min-width: 992px) { width:680px; }
  @media (min-width: 1200px) { width:1020px; }

  > li {
    position: relative;
    overflow: hidden;
    width: 320px;
    height: 235px;
    float:left;
    background-color: white;
    border-top: 1px solid var(--f-g3);
    box-shadow: 0px 9px 15px -10px rgba(0, 0, 0, 0.7);
    margin: 12px 10px 25px 10px;

    .front,
    .back {
      border-bottom: 4px solid var(--f-g5);
      background:var(--f-g1);
    }

    &:hover .tip-content {
      top: -235px;
    }
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 12px;
    font-size:20px;
    font-weight: bold;
    text-align: center;

    &::after {
      content: none !important;
    }
  }

  .desc {
    font-size:16px;
    margin-top:20px;
  }
}

.tips
.go{
  position:absolute;
  width:300px;
}

.go {
  bottom: 90px;
}

.tip-content {
  position: absolute;
  width: 320px;
  height: 470px;
  top: 0px;
  transition: all 400ms ease 0s;
}

.front, .back {
  padding: 10px;
  height: 235px;
}

.show-back {
  top: -235px;
}

h1 a, h2 a,
h1 a:hover, h2 a:hover,
h1 a:focus, h2 a:focus {
  text-decoration:none;
}
</style>
